import React, { useEffect, useRef, useState } from "react";

// react-step-wizard
import StepWizard from "react-step-wizard";

// components
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  SuccessStep,
  StepNavigation,
} from "components";
import { useCurrentUser } from "../hooks/useCurrentUser";

// !💩💩💩

export const SignUp: React.FC<{}> = () => {
  const wizardRef = useRef<any>(null);
  const { user } = useCurrentUser();

  const [labelArray] = useState<Array<string>>(["Step 1", "Step 2", "Step 3"]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [telephone, setTelephone] = useState<string | null>(null);

  useEffect(() => {
    console.log("SignUp updated user %o", user);

    if (user) {
      const step = user.nickname ? 4 : 3;
      console.log("User on step", step);

      setCurrentStep(step);
      wizardRef.current.goToStep(step);
    }
  }, [user]);

  return (
    <section>
      <h1 className="proxima-bold text-white text-center text-3xl">
        {currentStep === 4 ? "You’re all settled" : "Sign up"}
      </h1>
      <StepNavigation labelArray={labelArray} currentStep={currentStep} />
      <div className="md:mx-auto  md:max-w-3xl sm:mx-[40px] mx-[15px]">
        <section className="bg-glass rounded-[47px] shadow-panel">
          <div className="flex flex-col justify-center min-h-fit pt-8 overflow-hidden">
            <StepWizard
              key={currentStep}
              initialStep={currentStep}
              transitions={{
                enterLeft: "",
                enterRight: "",
                exitLeft: "",
                exitRight: "",
              }}
              instance={(wizard) => (wizardRef.current = wizard)}
            >
              <FirstStep
                setCurrentStep={setCurrentStep}
                handleSetTelephone={setTelephone}
              />
              <SecondStep
                setCurrentStep={setCurrentStep}
                telephone={telephone}
              />
              <ThirdStep setCurrentStep={setCurrentStep} />
              <SuccessStep />
            </StepWizard>
          </div>
          {currentStep !== 4 && (
            <div className="relative mx-auto text-center  max-w-[300px] py-[28px]">
              <span className="text-[15px] leading-5 text-black/30">
                By signing up you agree to our <br />
                <a
                  href="https://www.looky.com/documents/terms-and-conditions-of-service"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://www.looky.com/documents/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Privacy Policy
                </a>
              </span>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};
