export function LogoHeader() {
  return (
    <svg
      width='236'
      height='52'
      viewBox='0 0 236 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='lg:mx-[160px] sm:mx-[40px] mx-auto'
    >
      <path
        d='M198.001 35.2202C198.25 35.7081 198.379 36.2481 198.379 36.7959V47.9701C198.379 49.8845 199.931 51.4365 201.846 51.4365H216.906C218.821 51.4365 220.373 49.8845 220.373 47.9701V36.8019C220.373 36.2503 220.504 35.7066 220.757 35.2161L235.611 6.34161C236.798 4.0347 235.123 1.28946 232.529 1.28946H217.372C215.872 1.28946 214.542 2.2546 214.076 3.68091L210.51 14.6135C210.164 15.6763 208.66 15.6763 208.313 14.6135L204.747 3.68091C204.282 2.2546 202.952 1.28946 201.452 1.28946H186.345C183.756 1.28946 182.081 4.02516 183.258 6.33152L198.001 35.2202Z'
        fill='white'
      />
      <path
        d='M198.001 35.2202C198.25 35.7081 198.379 36.2481 198.379 36.7959V47.9701C198.379 49.8845 199.931 51.4365 201.846 51.4365H216.906C218.821 51.4365 220.373 49.8845 220.373 47.9701V36.8019C220.373 36.2503 220.504 35.7066 220.757 35.2161L235.611 6.34161C236.798 4.0347 235.123 1.28946 232.529 1.28946H217.372C215.872 1.28946 214.542 2.2546 214.076 3.68091L210.51 14.6135C210.164 15.6763 208.66 15.6763 208.313 14.6135L204.747 3.68091C204.282 2.2546 202.952 1.28946 201.452 1.28946H186.345C183.756 1.28946 182.081 4.02516 183.258 6.33152L198.001 35.2202Z'
        fill='url(#paint0_linear_360_10360)'
      />
      <path
        d='M132.641 1.15546C130.726 1.15546 129.174 2.70741 129.174 4.62184V47.8361C129.174 49.7505 130.726 51.3025 132.641 51.3025H146.411C148.326 51.3025 149.878 49.7505 149.878 47.8361V35.6756C149.878 35.404 150.098 35.1838 150.37 35.1838C150.552 35.1838 150.719 35.2846 150.804 35.4457L158.215 49.4567C158.815 50.5922 159.994 51.3025 161.279 51.3025H175.923C178.585 51.3025 180.254 48.4253 178.931 46.1144L168.64 28.1312C168.063 27.1222 168.03 25.891 168.552 24.8524L177.941 6.17905C179.101 3.8738 177.425 1.15546 174.845 1.15546H160.744C159.366 1.15546 158.118 1.97243 157.567 3.23612L151.62 16.8717C151.476 17.2032 151.148 17.4174 150.787 17.4174C150.285 17.4174 149.878 17.0104 149.878 16.5083V4.62184C149.878 2.70741 148.326 1.15546 146.411 1.15546H132.641Z'
        fill='white'
      />
      <path
        d='M132.641 1.15546C130.726 1.15546 129.174 2.70741 129.174 4.62184V47.8361C129.174 49.7505 130.726 51.3025 132.641 51.3025H146.411C148.326 51.3025 149.878 49.7505 149.878 47.8361V35.6756C149.878 35.404 150.098 35.1838 150.37 35.1838C150.552 35.1838 150.719 35.2846 150.804 35.4457L158.215 49.4567C158.815 50.5922 159.994 51.3025 161.279 51.3025H175.923C178.585 51.3025 180.254 48.4253 178.931 46.1144L168.64 28.1312C168.063 27.1222 168.03 25.891 168.552 24.8524L177.941 6.17905C179.101 3.8738 177.425 1.15546 174.845 1.15546H160.744C159.366 1.15546 158.118 1.97243 157.567 3.23612L151.62 16.8717C151.476 17.2032 151.148 17.4174 150.787 17.4174C150.285 17.4174 149.878 17.0104 149.878 16.5083V4.62184C149.878 2.70741 148.326 1.15546 146.411 1.15546H132.641Z'
        fill='url(#paint1_linear_360_10360)'
      />
      <path
        d='M24.6714 33.2403C22.757 33.2403 21.205 31.6883 21.205 29.7739V4.75584C21.205 2.84141 19.6531 1.28946 17.7386 1.28946H3.46638C1.55195 1.28946 0 2.84141 0 4.75584V47.9701C0 49.8845 1.55195 51.4365 3.46638 51.4365H36.8661C38.7806 51.4365 40.3325 49.8845 40.3325 47.9701V36.7066C40.3325 34.7922 38.7806 33.2403 36.8661 33.2403H24.6714Z'
        fill='white'
      />
      <path
        d='M24.6714 33.2403C22.757 33.2403 21.205 31.6883 21.205 29.7739V4.75584C21.205 2.84141 19.6531 1.28946 17.7386 1.28946H3.46638C1.55195 1.28946 0 2.84141 0 4.75584V47.9701C0 49.8845 1.55195 51.4365 3.46638 51.4365H36.8661C38.7806 51.4365 40.3325 49.8845 40.3325 47.9701V36.7066C40.3325 34.7922 38.7806 33.2403 36.8661 33.2403H24.6714Z'
        fill='url(#paint2_linear_360_10360)'
      />
      <path
        d='M41.5966 25.9979C41.5966 11.6396 53.0367 0 67.1488 0C72.3455 0 77.18 1.57842 81.2142 4.28976C76.2876 8.52831 72.8233 14.4716 71.7007 21.2249C70.5238 20.0627 68.9184 19.3473 67.1488 19.3473C63.5387 19.3473 60.6122 22.3248 60.6122 25.9979C60.6122 29.6709 63.5387 32.6485 67.1488 32.6485C70.7527 32.6485 73.6754 29.6811 73.6854 26.0168C73.6824 17.6746 77.7148 9.69812 84.3817 4.84299C88.5679 1.79431 93.6968 0 99.2376 0C113.35 0 124.79 11.6396 124.79 25.9979C124.79 40.3561 113.35 51.9957 99.2376 51.9957C94.0409 51.9957 89.2065 50.4173 85.1722 47.706C90.0988 43.4675 93.563 37.5242 94.6856 30.7709C95.8626 31.933 97.468 32.6485 99.2376 32.6485C102.848 32.6485 105.774 29.6709 105.774 25.9979C105.774 22.3248 102.848 19.3473 99.2376 19.3473C95.6151 19.3473 92.6812 22.3547 92.7011 26.0357C92.6897 34.1956 88.9833 41.4743 83.1932 46.2328C82.8062 46.5509 82.4099 46.8577 82.0047 47.1528C77.8184 50.2014 72.6896 51.9957 67.1488 51.9957C53.0367 51.9957 41.5966 40.3561 41.5966 25.9979Z'
        fill='white'
      />
      <path
        d='M41.5966 25.9979C41.5966 11.6396 53.0367 0 67.1488 0C72.3455 0 77.18 1.57842 81.2142 4.28976C76.2876 8.52831 72.8233 14.4716 71.7007 21.2249C70.5238 20.0627 68.9184 19.3473 67.1488 19.3473C63.5387 19.3473 60.6122 22.3248 60.6122 25.9979C60.6122 29.6709 63.5387 32.6485 67.1488 32.6485C70.7527 32.6485 73.6754 29.6811 73.6854 26.0168C73.6824 17.6746 77.7148 9.69812 84.3817 4.84299C88.5679 1.79431 93.6968 0 99.2376 0C113.35 0 124.79 11.6396 124.79 25.9979C124.79 40.3561 113.35 51.9957 99.2376 51.9957C94.0409 51.9957 89.2065 50.4173 85.1722 47.706C90.0988 43.4675 93.563 37.5242 94.6856 30.7709C95.8626 31.933 97.468 32.6485 99.2376 32.6485C102.848 32.6485 105.774 29.6709 105.774 25.9979C105.774 22.3248 102.848 19.3473 99.2376 19.3473C95.6151 19.3473 92.6812 22.3547 92.7011 26.0357C92.6897 34.1956 88.9833 41.4743 83.1932 46.2328C82.8062 46.5509 82.4099 46.8577 82.0047 47.1528C77.8184 50.2014 72.6896 51.9957 67.1488 51.9957C53.0367 51.9957 41.5966 40.3561 41.5966 25.9979Z'
        fill='url(#paint3_linear_360_10360)'
      />
      <path
        d='M41.5966 25.9979C41.5966 11.6396 53.0367 0 67.1488 0C72.3455 0 77.18 1.57842 81.2142 4.28976C76.2876 8.52831 72.8233 14.4716 71.7007 21.2249C70.5238 20.0627 68.9184 19.3473 67.1488 19.3473C63.5387 19.3473 60.6122 22.3248 60.6122 25.9979C60.6122 29.6709 63.5387 32.6485 67.1488 32.6485C70.7527 32.6485 73.6754 29.6811 73.6854 26.0168C73.6824 17.6746 77.7148 9.69812 84.3817 4.84299C88.5679 1.79431 93.6968 0 99.2376 0C113.35 0 124.79 11.6396 124.79 25.9979C124.79 40.3561 113.35 51.9957 99.2376 51.9957C94.0409 51.9957 89.2065 50.4173 85.1722 47.706C90.0988 43.4675 93.563 37.5242 94.6856 30.7709C95.8626 31.933 97.468 32.6485 99.2376 32.6485C102.848 32.6485 105.774 29.6709 105.774 25.9979C105.774 22.3248 102.848 19.3473 99.2376 19.3473C95.6151 19.3473 92.6812 22.3547 92.7011 26.0357C92.6897 34.1956 88.9833 41.4743 83.1932 46.2328C82.8062 46.5509 82.4099 46.8577 82.0047 47.1528C77.8184 50.2014 72.6896 51.9957 67.1488 51.9957C53.0367 51.9957 41.5966 40.3561 41.5966 25.9979Z'
        fill='white'
      />
      <path
        d='M41.5966 25.9979C41.5966 11.6396 53.0367 0 67.1488 0C72.3455 0 77.18 1.57842 81.2142 4.28976C76.2876 8.52831 72.8233 14.4716 71.7007 21.2249C70.5238 20.0627 68.9184 19.3473 67.1488 19.3473C63.5387 19.3473 60.6122 22.3248 60.6122 25.9979C60.6122 29.6709 63.5387 32.6485 67.1488 32.6485C70.7527 32.6485 73.6754 29.6811 73.6854 26.0168C73.6824 17.6746 77.7148 9.69812 84.3817 4.84299C88.5679 1.79431 93.6968 0 99.2376 0C113.35 0 124.79 11.6396 124.79 25.9979C124.79 40.3561 113.35 51.9957 99.2376 51.9957C94.0409 51.9957 89.2065 50.4173 85.1722 47.706C90.0988 43.4675 93.563 37.5242 94.6856 30.7709C95.8626 31.933 97.468 32.6485 99.2376 32.6485C102.848 32.6485 105.774 29.6709 105.774 25.9979C105.774 22.3248 102.848 19.3473 99.2376 19.3473C95.6151 19.3473 92.6812 22.3547 92.7011 26.0357C92.6897 34.1956 88.9833 41.4743 83.1932 46.2328C82.8062 46.5509 82.4099 46.8577 82.0047 47.1528C77.8184 50.2014 72.6896 51.9957 67.1488 51.9957C53.0367 51.9957 41.5966 40.3561 41.5966 25.9979Z'
        fill='url(#paint4_linear_360_10360)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.8362 24.2647C61.5857 21.4321 64.1279 19.3473 67.1487 19.3473C68.9183 19.3473 70.5237 20.0627 71.7006 21.2249C72.8232 14.4716 76.2875 8.52831 81.2141 4.28976C77.1798 1.57842 72.3454 0 67.1487 0C53.609 0 42.5289 10.7146 41.6523 24.2647H60.8362ZM90.1222 37.3927C89.7791 38.1074 89.4047 38.8038 89.0009 39.4799L90.1222 37.3927ZM105.55 27.7311C104.8 30.5637 102.258 32.6485 99.2375 32.6485C97.4678 32.6485 95.8625 31.933 94.6855 30.7709C93.5629 37.5242 90.0987 43.4675 85.1721 47.706C89.2063 50.4173 94.0407 51.9957 99.2375 51.9957C112.777 51.9957 123.857 41.2812 124.734 27.7311H105.55Z'
        fill='url(#paint5_linear_360_10360)'
        fillOpacity='0.8'
      />
      <defs>
        <linearGradient
          id='paint0_linear_360_10360'
          x1='235.974'
          y1='10.965'
          x2='205.101'
          y2='102.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_360_10360'
          x1='235.974'
          y1='10.965'
          x2='205.101'
          y2='102.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_360_10360'
          x1='235.974'
          y1='10.965'
          x2='205.101'
          y2='102.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_360_10360'
          x1='235.974'
          y1='10.965'
          x2='205.101'
          y2='102.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_360_10360'
          x1='235.974'
          y1='10.965'
          x2='205.101'
          y2='102.304'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_360_10360'
          x1='67.0166'
          y1='13.2878'
          x2='103.828'
          y2='31.5157'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0' />
          <stop offset='0.288818' />
          <stop offset='0.635344' />
          <stop offset='0.997212' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
