import React, { useState, useEffect } from "react";

// components
import { PhoneField, Button } from "components";

// hooks
import { usePosition } from "hooks";

// services
import { otp } from "services";

// others
import { SpinnerCircular } from "spinners-react";
import { FormProvider, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { isValidPhoneNumber } from "react-phone-number-input";

// interfaces
interface IFirstStep extends Partial<StepWizardChildProps> {
  setCurrentStep: (state: number) => void;
  handleSetTelephone: (state: string) => void;
}

// types
type PhoneFormFields = { phone_number: string };

export const FirstStep: React.FC<IFirstStep> = ({
  nextStep,
  setCurrentStep,
  handleSetTelephone,
}) => {
  const countryCode = usePosition();
  const formMethods = useForm<PhoneFormFields>({ mode: "onChange" });
  const { handleSubmit, setError } = formMethods;
  const [captcha, setCaptcha] = useState("");
  const [disabled, setDisabled] = useState(true);

  const renderCaptcha = (): void => {
    grecaptcha.ready(() => {
      grecaptcha.render("recaptcha-container", {
        sitekey:
          process.env.NEXT_PUBLIC_RECAPTCHA_KEY_V2 ||
          "6LfuJgEgAAAAAN4jz3dy5LqsFeL5D3egQMn1MB0C",
        callback: (token: string) => {
          setCaptcha(token);
          setDisabled(false);
        },
      });
    });
  };

  useEffect(() => {
    renderCaptcha();
  }, []);

  const isPhoneNumberValid = async (phone_number: string): Promise<any> => {
    if (!isValidPhoneNumber(`+${phone_number}`)) {
      setError("phone_number", {
        message: "error",
      });
      return false;
    }
    return true;
  };

  const requestOTP = async ({
    phone_number,
  }: PhoneFormFields): Promise<void> => {
    if (!isPhoneNumberValid(phone_number)) return;

    try {
      await otp({
        phone_number,
        [captcha ? "recaptcha_token_v2" : "recaptcha_token_v3"]: captcha
          ? captcha
          : "response",
      });

      handleSetTelephone(phone_number);
      setCurrentStep(2);
      nextStep?.();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <h1 className="mt-4 mb-8 text-2xl font-semibold text-center">
          Enter phone number
        </h1>
        <form
          onSubmit={handleSubmit(requestOTP)}
          className="flex justify-center"
        >
          <div className="flex justify-center">
            <div>
              <PhoneField countryCode={countryCode ? countryCode : ""} />

              <div
                id="recaptcha-container"
                className="flex justify-center mt-[10px] h-[80px]"
              />
              {countryCode ? (
                <>
                  <Button
                    className="mt-[20px] text-base"
                    type="submit"
                    disabled={disabled}
                    style={{ opacity: disabled ? 0.5 : 1 }}
                  >
                    Get sign up code
                  </Button>
                </>
              ) : (
                <SpinnerCircular />
              )}
              {/* <div
                id="recaptcha-container"
                className="flex justify-center mt-[10px] h-[80px]"
              /> */}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
