import React from "react"

// components
import { IconStep } from "components"

import clsx from "clsx"

// interfaces
interface IStep {
  label: string
  index: number
  selected: boolean
  lastStep: boolean
}

export const Step: React.FC<IStep> = ({ index, selected, lastStep }) => (
  <div
    className={clsx(
      "stepBlock relative w-[35%] z-10",
      lastStep && "step-line",
    )}>
    <div
      className={clsx(
        "circleWrapper py-0 px-[20px] relative z-10",
        selected && "",
      )}>
      <div
        className={clsx(
          "w-[36px] h-[36px] flex items-center justify-center leading-[25px] bg-white text-black rounded-[50%] text-center text-[20px] font-bold",
          {
            // eslint-disable-next-line
            ["selected text-white"]: lastStep || selected,
            // eslint-disable-next-line
            [" scale-125"]: selected,
          },
        )}>
        {lastStep ? (
          <div className="relative flex justify-center">
            <IconStep />
          </div>
        ) : (
          <span
            className={clsx({
              // eslint-disable-next-line
              ["text-white text-2xl leading-3"]: selected || lastStep,
            })}>
            {index + 1}
          </span>
        )}
      </div>
    </div>
  </div>
)
