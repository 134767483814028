import { current_user } from "../services/api"
import React from "react"
import { useContext, useEffect, useState } from "react"

const CurrentUserContext = React.createContext<{
  user: User | null
  update: () => Promise<void>
  error: null | string | number
}>({ user: null, update: () => new Promise(() => {}), error: null })

type User = {
  nickname: string
  posts_count: string
}

export const CurrentUserProvider = ({ children }: any) => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    getUser()
  }, [])

  const getUser = () => {
    return current_user()
      .then(resp => {
        setUser(resp.data)
      })
      .catch(error => setError(error.response.status))
  }

  return (
    <CurrentUserContext.Provider value={{ user, update: getUser, error }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => {
  return useContext(CurrentUserContext)
}
