export function IconStep() {
  return (
    <svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.14453 13.4814L4.88825 10.2252L13.4853 1.6281C14.3845 0.728899 15.8424 0.728898 16.7416 1.62809C17.6408 2.52729 17.6408 3.98518 16.7416 4.88437L8.14453 13.4814Z'
        fill='white'
      />
      <path
        d='M11.3984 10.2256L8.14216 13.4819L1.62961 6.96931C0.730413 6.07012 0.73041 4.61223 1.62961 3.71303C2.5288 2.81384 3.98669 2.81384 4.88588 3.71303L11.3984 10.2256Z'
        fill='white'
      />
    </svg>
  )
}
