import { useState, useEffect } from 'react'

export function usePosition(): string | null {
  const [code, setCode] = useState<string | null>(null)

  useEffect(() => {
    async function getPosition(): Promise<void> {
      try {
        const position = await fetch('https://get.geojs.io/v1/ip/geo.json')
        const json = await position.json()
        setCode(json.country_code.toLowerCase() || 'us')
      } catch (err) {
        setCode('us')
      }
    }
    getPosition()
  }, [])

  return code
}
