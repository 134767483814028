import axios from "../axios"

// interfaces
interface IOtpData {
  phone_number: string | null
  recaptcha_token_v2?: string
  recaptcha_token_v3?: string
}

interface ISignIn {
  otp: string
}

axios.defaults.withCredentials = true

const otp = (data: IOtpData) => axios.post("/users/otp", data)

const sign_in = (data: ISignIn) => axios.post("/users/sign_in", data)

const current_user = () => axios.get("/current_user")

const posts = () => axios.get("/users/instagram_posts")

export { otp, sign_in, current_user, posts }
