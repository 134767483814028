import React from "react";

// components
import { LogoFooter } from "components";

export const Footer: React.FC<{}> = () => (
  <footer className="flex flex-col sm:flex-row justify-center sm:justify-between text-white py-[40px] lg:mx-[160px] sm:mx-[40px] mx-auto">
    <div className="mx-auto sm:mx-0">
      <div className="sm:pb-4 pb-2">
        <LogoFooter />
      </div>
      <span className="text-[15px] leading-4 opacity-50 underline">
        Copyright © 2022 Looky
      </span>
    </div>
    <div className="flex items-center pt-1 sm:pt-0">
      <div className="mx-[9px]">
        <a
          href="https://www.looky.com/documents/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="text-[15px] leading-4 opacity-50 underline"
        >
          Privacy policy
        </a>
      </div>
      <div className="mx-[9px]">
        <a
          href="https://www.looky.com/documents/terms-and-conditions-of-service"
          target="_blank"
          rel="noreferrer"
          className="text-[15px] leading-4 opacity-50 underline"
        >
          Terms&Conditions
        </a>
      </div>
    </div>
  </footer>
);
