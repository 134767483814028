export function IconSuccess() {
  return (
    <svg width='67' height='52' viewBox='0 0 67 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M29.6348 51.0322L16.584 37.9815L51.04 3.52557C54.6438 -0.0782847 60.4868 -0.0782874 64.0907 3.52557C67.6946 7.12943 67.6946 12.9724 64.0907 16.5763L29.6348 51.0322Z'
        fill='#00FF47'
      />
      <path
        d='M42.6777 37.9834L29.627 51.0341L3.52557 24.9327C-0.0782925 21.3288 -0.0782991 15.4858 3.52556 11.882C7.12941 8.27809 12.9724 8.27809 16.5763 11.8819L42.6777 37.9834Z'
        fill='#00FF47'
      />
    </svg>
  )
}
