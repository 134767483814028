import React from "react"

// Container
import { SignUp } from "containers"

// Components
import { Header, Footer } from "components"

import "./App.scss"

const App: React.FC<{}> = () => {
  return (
    <div className="root">
      <div className="flex flex-col justify-between mx-auto background-container">
        <div>
          <Header />
          <SignUp />
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default App
