import React, { useEffect, useState } from "react"

// components
import { Button } from "components"

//  others
import { SpinnerCircular } from "spinners-react"
import { useCurrentUser } from "../../hooks/useCurrentUser"
import { posts as getPosts } from "../../services/api"

export const SuccessStep: React.FC<{}> = () => {
  const { user } = useCurrentUser()
  const [posts, setPosts] = useState<Array<{ url: string }>>([])

  useEffect(() => {
    if (user) {
      getPosts().then(response => setPosts(response.data.feed))
    }
  }, [user])

  return (
    <>
      {!user ? (
        <div className="flex justify-center">
          <SpinnerCircular />
        </div>
      ) : (
        <div className="justify-start">
          <div className="text-center pt-[30px] pb-[30px]">
            <span className="proxima-bold text-black leading-[24.36px]">
              Congrats!
            </span>
          </div>
          <div className="flex justify-center">
            <div className="absolute  2md:left-[-110px] xs:left-[10px] left-[0px]  2md:w-auto 2md:h-auto w-[270px] h-[270px]">
              <img
                className="2md:mt-[0px] mt-[25px]"
                src={require("./assets/instagram_connect.png")}
                alt=""
              />
            </div>
            <div>
              <div className="text-center">
                <span className="proxima-bold text-black leading-[24.36px]">
                  @{user.nickname}
                </span>
              </div>
              <div className="flex justify-center pt-[30px] pb-[15px]">
                <img className="" src={require("./assets/arrow.png")} alt="" />
              </div>
              <div className="text-center pt-[5px] pb-[45px]">
                <span className="block proxima-bold text-black text-[40px] leading-[48.72px]">
                  {user.posts_count}
                </span>

                <span className="block proxima-bold text-[#757575]">Posts</span>
              </div>
            </div>
            <div className="absolute 2md:right-[-85px] xs:right-[10px] right-[-10px] 2md:w-auto 2md:h-auto w-[250px] h-[250px]">
              <img
                className="2md:mt-[25px] mt-[40px]"
                src={require("./assets/looky_connect.png")}
                alt=""
              />
            </div>
          </div>
          <div className="w-[256px] mx-auto mb-4 text-center">
            <span className="proxima-bold text-[#757575] text-center text-[15px] leading-[18.27px]">
              All of your treasures will appear in Looky app upon launch
            </span>
          </div>

          {!posts.length ? (
            <div className="flex justify-center">
              <SpinnerCircular />
            </div>
          ) : (
            <div className="flex flex-wrap w-full gap-4 px-[15px]">
              {posts.map((item, i) => (
                <div
                  className="relative rounded w-[31.7333333%] pb-[33.33333%]"
                  key={i}>
                  <MediaElement src={item.url} />
                </div>
              ))}
            </div>
          )}

          <div className="w-[367px] mt-[50px] mb-[40px]  mx-auto text-center">
            <span className="proxima-bold text-[#757575] text-center text-[15px] leading-[18.27px]">
              Your posts successfully imported. We will send you
              push-notification when our application hits the Store. Can't wait
              to see you at Looky Social Network!
            </span>
          </div>
          <a
            href="https://looky.com"
            className="block mx-auto w-[190px] pb-[50px]">
            <Button variant="instagram">Got it, thx!</Button>
          </a>
        </div>
      )}
    </>
  )
}

const MediaElement = ({ src }: { src: string }) => {
  if (src.includes(".mp4"))
    return (
      <video src={src} className="absolute object-cover w-full h-full "></video>
    )
  else
    return (
      <img src={src} alt="" className="absolute object-cover w-full h-full " />
    )
}
