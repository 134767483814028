import React from "react";

// components
import { LogoHeader } from "components";

export const Header: React.FC<{}> = () => (
  <header className="py-6">
    <div>
      <LogoHeader />
    </div>
  </header>
);
