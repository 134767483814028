import React from "react"

// components
import { Button } from "components"

// others
import { useForm } from "react-hook-form"
import { StepWizardChildProps } from "react-step-wizard"

// interfaces
interface IThirdStep extends Partial<StepWizardChildProps> {
  setCurrentStep: (state: number) => void
}

export const ThirdStep: React.FC<IThirdStep> = ({
  nextStep,
  setCurrentStep,
}) => {
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const connectToInstagram = async () => {
    let authURL = `${process.env.REACT_APP_API_ROUTE}/users/auth/instagram_graph`
    window.location.replace(authURL)
  }

  return (
    <form
      onSubmit={handleSubmit(connectToInstagram)}
      className="flex justify-center">
      <div className="">
        <div className="pt-[30px] mx-auto">
          <p className="text-center text-black  text-[25px] proxima-bold">
            Connect your Instagram account
          </p>
        </div>
        <div className="pt-[30px] relative">
          <img
            className="mx-auto object-fit"
            src={require("./assets/instagram.png")}
            alt=""
          />
          <div className="absolute w-[180px] h-[180px] -z-10 -translate-x-1/2 -translate-y-1/2 rounded-full left-1/2 top-[57%] instagram-png"></div>
        </div>
        <div className="mt-20 mb-16">
          <Button variant="instagram" type="submit">
            Connect account
          </Button>
        </div>
      </div>
    </form>
  )
}
