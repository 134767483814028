import React from "react";

// components
import { Step } from "./Step";

// interfaces
interface IStepNavigation {
  labelArray: Array<string>;
  currentStep: number;
}

export const StepNavigation: React.FC<IStepNavigation> = ({
  labelArray,
  currentStep,
}) => (
  <div className="flex justify-center proxima-bold mx-auto mt-5 mb-5 max-w-[700px]">
    {labelArray.map((item: string, i: number) => (
      <Step
        key={i}
        index={i}
        label={item}
        lastStep={currentStep > i + 1}
        selected={currentStep === i + 1}
      />
    ))}
  </div>
);
