import React from 'react'

// other
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

interface CountDownProps {
  duration?: number
  colors?: any
  size?: number
  widthLine?: number
  isPlaying?: boolean
  onComplete?: any
}

export const CountDown: React.FC<CountDownProps> = ({
  duration = 300,
  colors = '#888888',
  size = 55,
  widthLine = 5,
  isPlaying,
  onComplete
}) => (
  <CountdownCircleTimer
    isPlaying={isPlaying}
    duration={duration}
    colors={colors}
    size={size}
    strokeWidth={widthLine}
    onComplete={onComplete}
  >
    {({ remainingTime }) => {
      return (
        <div className='flex flex-col text-center mb-1'>
          <span className='block text-xs proxima-bold'>{remainingTime}</span>
          <span className='block text-[10px] leading-[5px]'>sec</span>
        </div>
      )
    }}
  </CountdownCircleTimer>
)
