import React from "react"

// other
import VerificationInput from "react-verification-input"
import { Controller, useFormContext } from "react-hook-form"

interface OTPInputProps {
  onSubmit: any
  success?: boolean
  error?: boolean
  request?: boolean
}

export const OTPInput: React.FC<OTPInputProps> = ({
  onSubmit,
  success = true,
  error = false,
  request,
}) => {
  const { control, handleSubmit } = useFormContext()

  return (
    <Controller
      name="otp"
      control={control}
      render={({ field }) => (
        <VerificationInput
          removeDefaultStyles
          placeholder=""
          validChars="0-9"
          inputProps={{ id: "otp-input", autoComplete: "one-time-code" }}
          onChange={value => {
            field.onChange(value)
            if (value.length === 6) return handleSubmit(onSubmit)()
          }}
          classNames={{
            container: "h-[87px] gap-2 justify-center",
            character: `proxima-extrabold character text-2xl leading-[87px] border border-black/80 rounded-md !basis-[48px] !grow-0
             ${success && "border-[#65FF90]"}
             ${(error) && "ring-red-600 border-red-600 border-2"}`,

            characterSelected: "ring-2 ring-black/50",
          }}
        />
      )}
    />
  )
}
