import React, { MouseEventHandler, ButtonHTMLAttributes } from 'react'
import clsx from 'clsx'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'instagram'
  onClick?: MouseEventHandler
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'default',
  type = 'button',
  onClick,
  className,
  children,
  ...restProps
}) => (
  <button
    type={type}
    onClick={onClick}
    className={clsx(
      'btn',
      {
        ['btn-' + variant]: variant,
      },
      className
    )}
    {...restProps}
  >
    {children}
  </button>
)
