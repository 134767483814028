export function IconError() {
  return (
    <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.4424 13.9532L14.4371 1.97762C13.7338 0.758398 12.4229 0.00166216 11.0053 2.73545e-06C9.58755 -0.00165687 8.27626 0.751956 7.56422 1.97767L0.55628 13.9576C-0.170394 15.1795 -0.186834 16.7105 0.517031 17.9592C1.22148 19.2089 2.54051 19.9866 3.97525 19.9983L18.0131 19.9983C19.4628 19.9842 20.7793 19.2089 21.4836 17.9603C22.1871 16.7132 22.1714 15.1856 21.4424 13.9532ZM2.27894 14.9737L9.29206 2.98491C9.64723 2.37354 10.2986 1.99918 11.003 2C11.7073 2.00083 12.3584 2.37673 12.7077 2.98222L19.7186 14.9672C20.086 15.5885 20.0939 16.3533 19.7417 16.9777C19.389 17.603 18.7295 17.9913 18.0034 17.9984L3.98339 17.9983C3.27278 17.9925 2.61208 17.6029 2.25929 16.9771C1.90689 16.3519 1.91512 15.5855 2.27894 14.9737ZM11.0012 15.9983C11.5537 15.9983 12.0015 15.5506 12.0015 14.9983C12.0015 14.4461 11.5537 13.9983 11.0012 13.9983C10.4487 13.9983 10.0009 14.4461 10.0009 14.9983C10.0009 15.5506 10.4487 15.9983 11.0012 15.9983ZM12.0045 5.99835H10.0038V12.9983H12.0045V5.99835Z'
        fill='#FF0000'
      />
    </svg>
  )
}
