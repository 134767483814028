import axios from "axios"

const axiosInstance = axios.create({
  baseURL: "https://landing-api.looky.com/api/v1",
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  withCredentials: true,
  auth: { username: "api", password: "Looky22" },
})

export default axiosInstance
