import React from 'react'

// others
import { Controller, useFormContext } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

// interfaces
interface IPhoneField {
  countryCode: string
}

export const PhoneField: React.FC<IPhoneField> = ({ countryCode }) => {
  const { control, trigger } = useFormContext()

  return (
    <Controller
      name={'phone_number'}
      control={control}
      render={props => {
        return (
          <PhoneInput
            buttonClass='ml-3'
            {...props.field}
            onChange={value => {
              trigger('phone_number')
              props.field.onChange(value)
            }}
            prefix='+'
            inputProps={{
              id: 'id_phone_number',
              name: 'phone_number',
              required: true,
              className:
                'bg-transparent w-full mx-auto pl-[65px] h-[72px] w-[300px] focus:ring focus:ring-amber-600 py-3 ring-main text-[25px] font-medium ring-1 ring-black/20 rounded-full',
              autoComplete: 'off',
            }}
            country={countryCode}
            specialLabel='Enter phone'
          />
        )
      }}
    />
  )
}
