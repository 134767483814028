import React, { useState, useEffect } from "react";

// components
import { CountDown, OTPInput, IconSuccess, IconError } from "components";

// services
import { sign_in, otp } from "services";

// styles
import clsx from "clsx";

// other
import { FormProvider, useForm } from "react-hook-form";
import { StepWizardChildProps } from "react-step-wizard";
import { useCurrentUser } from "../../hooks/useCurrentUser";

// interfaces
interface ISecondStep extends Partial<StepWizardChildProps> {
  setCurrentStep: (state: number) => void;
  telephone: string | null;
}

export const SecondStep: React.FC<ISecondStep> = ({
  nextStep,
  telephone,
  currentStep,
  setCurrentStep,
}) => {
  const formMethods = useForm({ mode: "onChange" });
  const { update } = useCurrentUser();
  const [isPlaying, setPlaying] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (currentStep === 2) {
      setPlaying(true);
    }
  }, [currentStep]);

  const sendOTP = async ({ otp }: { otp: number }): Promise<void> => {
    try {
      await sign_in({ otp: String(otp) });
      setError(false);
      setSuccess(true);
      await update();
      setTimeout(() => {
        setCurrentStep(3);
        nextStep?.();
      }, 3000);
    } catch (err) {
      setPlaying(false);
      setError(true);
    }
  };

  const completeHandler = () => {
    renderCaptcha();
    setPlaying(false);
  };

  const handlerSendCodeAgain = async () => {
    try {
      await otp({
        phone_number: telephone,
        [captcha ? "recaptcha_token_v2" : "recaptcha_token_v3"]: captcha
          ? captcha
          : "",
      });
      setPlaying(true);
    } catch (err) {
      console.log(err);
    }
  };

  const renderCaptcha = (): void => {
    grecaptcha.ready(() => {
      grecaptcha.render("captcha-container", {
        sitekey:
          process.env.NEXT_PUBLIC_RECAPTCHA_KEY_V2 ||
          "6LfuJgEgAAAAAN4jz3dy5LqsFeL5D3egQMn1MB0C",
        callback: (token: string) => {
          setCaptcha(token);
          setDisabled(false);
        },
      });
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form id="otp-form">
        <p className="text-3xl font-semibold text-center text-black/80 mb-7">
          Enter code
        </p>
        <div className={clsx("sm:w-[70%] md:w-[60%] w-[80%] mb-8 mx-auto")}>
          <OTPInput
            onSubmit={sendOTP}
            success={success}
            error={error}
            request={!isPlaying}
          />
        </div>
        <div className="max-w-[300px] mx-auto">
          {isPlaying && !success && (
            <div className="flex justify-center mb-6">
              <CountDown
                duration={20}
                isPlaying={isPlaying}
                onComplete={completeHandler}
              />
            </div>
          )}
          {success && (
            <div>
              <div className="flex justify-center">
                <IconSuccess />
              </div>
              <div className="mt-5 text-center">
                <span className="text-[20px] leading-[24px] text-green-300">
                  Code accepted
                </span>
              </div>
            </div>
          )}
          {error && (
            <div>
              <div className="flex justify-center">
                <IconError />
              </div>
              <div className="mt-5 text-center">
                <span className="text-[15px] leading-[18px] text-[#FF0000]">
                  Sorry, your code is either out of date or wrong
                </span>
              </div>
            </div>
          )}
          {!isPlaying && !success && !error && (
            <div className="flex flex-col justify-center">
              <p className="text-[15x] text-center leading-tight proxima-medium font-medium text-black text-opacity-50">
                Didn't get your code?
                <br />
                No worries, we got you covered
              </p>
              <div
                id="captcha-container"
                className="flex justify-center mt-[10px] h-[80px]"
              />
              <p
                onClick={handlerSendCodeAgain}
                className={clsx(
                  "proxima-semibold cursor-pointer text-[15px] text-center w-[180px] mt-2 rounded bg-gray-200 py-1 px-2 font-semibold mx-auto text-black",
                  disabled && "opacity-50 pointer-events-none cursor-default"
                )}
              >
                Request another code
              </p>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};
