export function LogoFooter() {
  return (
    <svg
      width='108'
      height='24'
      viewBox='0 0 108 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M90.4028 16.0808C90.5165 16.3036 90.5758 16.5501 90.5758 16.8002V21.9021C90.5758 22.7762 91.2844 23.4848 92.1585 23.4848H99.0347C99.9087 23.4848 100.617 22.7762 100.617 21.9021V16.803C100.617 16.5511 100.677 16.3029 100.793 16.0789L107.575 2.89544C108.117 1.84216 107.352 0.588738 106.168 0.588738H99.2473C98.5623 0.588738 97.955 1.0294 97.7426 1.68062L96.1145 6.6722C95.9562 7.15748 95.2696 7.15748 95.1114 6.6722L93.4832 1.68062C93.2708 1.0294 92.6635 0.588738 91.9785 0.588738H85.0813C83.8991 0.588738 83.1342 1.8378 83.6716 2.89083L90.4028 16.0808Z'
        fill='white'
      />
      <path
        d='M90.4028 16.0808C90.5165 16.3036 90.5758 16.5501 90.5758 16.8002V21.9021C90.5758 22.7762 91.2844 23.4848 92.1585 23.4848H99.0347C99.9087 23.4848 100.617 22.7762 100.617 21.9021V16.803C100.617 16.5511 100.677 16.3029 100.793 16.0789L107.575 2.89544C108.117 1.84216 107.352 0.588738 106.168 0.588738H99.2473C98.5623 0.588738 97.955 1.0294 97.7426 1.68062L96.1145 6.6722C95.9562 7.15748 95.2696 7.15748 95.1114 6.6722L93.4832 1.68062C93.2708 1.0294 92.6635 0.588738 91.9785 0.588738H85.0813C83.8991 0.588738 83.1342 1.8378 83.6716 2.89083L90.4028 16.0808Z'
        fill='url(#paint0_linear_360_10352)'
      />
      <path
        d='M60.5608 0.527558C59.6867 0.527558 58.9782 1.23615 58.9782 2.11023V21.8409C58.9782 22.715 59.6867 23.4236 60.5608 23.4236H66.8483C67.7224 23.4236 68.4309 22.715 68.4309 21.8409V16.2887C68.4309 16.1647 68.5315 16.0642 68.6555 16.0642C68.7387 16.0642 68.8151 16.1102 68.854 16.1837L72.2374 22.5809C72.5115 23.0993 73.0499 23.4236 73.6364 23.4236H80.3225C81.5382 23.4236 82.3 22.1099 81.6962 21.0548L76.9975 12.8441C76.7339 12.3834 76.7188 11.8213 76.9572 11.347L81.2442 2.82122C81.7735 1.76869 81.0083 0.527558 79.8302 0.527558H73.3924C72.7629 0.527558 72.1933 0.900571 71.9417 1.47754L69.2265 7.70327C69.1605 7.85459 69.0111 7.95242 68.846 7.95242C68.6168 7.95242 68.4309 7.76658 68.4309 7.53734V2.11023C68.4309 1.23615 67.7224 0.527558 66.8483 0.527558H60.5608Z'
        fill='white'
      />
      <path
        d='M60.5608 0.527558C59.6867 0.527558 58.9782 1.23615 58.9782 2.11023V21.8409C58.9782 22.715 59.6867 23.4236 60.5608 23.4236H66.8483C67.7224 23.4236 68.4309 22.715 68.4309 21.8409V16.2887C68.4309 16.1647 68.5315 16.0642 68.6555 16.0642C68.7387 16.0642 68.8151 16.1102 68.854 16.1837L72.2374 22.5809C72.5115 23.0993 73.0499 23.4236 73.6364 23.4236H80.3225C81.5382 23.4236 82.3 22.1099 81.6962 21.0548L76.9975 12.8441C76.7339 12.3834 76.7188 11.8213 76.9572 11.347L81.2442 2.82122C81.7735 1.76869 81.0083 0.527558 79.8302 0.527558H73.3924C72.7629 0.527558 72.1933 0.900571 71.9417 1.47754L69.2265 7.70327C69.1605 7.85459 69.0111 7.95242 68.846 7.95242C68.6168 7.95242 68.4309 7.76658 68.4309 7.53734V2.11023C68.4309 1.23615 67.7224 0.527558 66.8483 0.527558H60.5608Z'
        fill='url(#paint1_linear_360_10352)'
      />
      <path
        d='M11.2644 15.1768C10.3903 15.1768 9.68175 14.4682 9.68175 13.5941V2.17141C9.68175 1.29733 8.97316 0.588738 8.09908 0.588738H1.58268C0.708589 0.588738 0 1.29733 0 2.17141V21.9021C0 22.7762 0.708588 23.4848 1.58268 23.4848H16.8323C17.7064 23.4848 18.415 22.7762 18.415 21.9021V16.7595C18.415 15.8854 17.7064 15.1768 16.8323 15.1768H11.2644Z'
        fill='white'
      />
      <path
        d='M11.2644 15.1768C10.3903 15.1768 9.68175 14.4682 9.68175 13.5941V2.17141C9.68175 1.29733 8.97316 0.588738 8.09908 0.588738H1.58268C0.708589 0.588738 0 1.29733 0 2.17141V21.9021C0 22.7762 0.708588 23.4848 1.58268 23.4848H16.8323C17.7064 23.4848 18.415 22.7762 18.415 21.9021V16.7595C18.415 15.8854 17.7064 15.1768 16.8323 15.1768H11.2644Z'
        fill='url(#paint2_linear_360_10352)'
      />
      <path
        d='M18.9921 11.8701C18.9921 5.31441 24.2154 0 30.6587 0C33.0314 0 35.2387 0.720672 37.0806 1.95861C34.8313 3.89384 33.2496 6.6074 32.737 9.69082C32.1996 9.16021 31.4667 8.83354 30.6587 8.83354C29.0104 8.83354 27.6742 10.193 27.6742 11.8701C27.6742 13.5471 29.0104 14.9066 30.6587 14.9066C32.3041 14.9066 33.6386 13.5518 33.6432 11.8787C33.6418 8.06985 35.4829 4.42795 38.5268 2.2112C40.4382 0.819244 42.7799 0 45.3097 0C51.753 0 56.9763 5.31441 56.9763 11.8701C56.9763 18.4257 51.753 23.7401 45.3097 23.7401C42.937 23.7401 40.7297 23.0195 38.8878 21.7815C41.1372 19.8463 42.7189 17.1327 43.2314 14.0493C43.7688 14.5799 44.5018 14.9066 45.3097 14.9066C46.958 14.9066 48.2942 13.5471 48.2942 11.8701C48.2942 10.193 46.958 8.83354 45.3097 8.83354C43.6558 8.83354 42.3162 10.2067 42.3253 11.8873C42.3201 15.613 40.6278 18.9363 37.9842 21.1089C37.8075 21.2541 37.6266 21.3942 37.4416 21.5289C35.5302 22.9209 33.1885 23.7401 30.6587 23.7401C24.2154 23.7401 18.9921 18.4257 18.9921 11.8701Z'
        fill='white'
      />
      <path
        d='M18.9921 11.8701C18.9921 5.31441 24.2154 0 30.6587 0C33.0314 0 35.2387 0.720672 37.0806 1.95861C34.8313 3.89384 33.2496 6.6074 32.737 9.69082C32.1996 9.16021 31.4667 8.83354 30.6587 8.83354C29.0104 8.83354 27.6742 10.193 27.6742 11.8701C27.6742 13.5471 29.0104 14.9066 30.6587 14.9066C32.3041 14.9066 33.6386 13.5518 33.6432 11.8787C33.6418 8.06985 35.4829 4.42795 38.5268 2.2112C40.4382 0.819244 42.7799 0 45.3097 0C51.753 0 56.9763 5.31441 56.9763 11.8701C56.9763 18.4257 51.753 23.7401 45.3097 23.7401C42.937 23.7401 40.7297 23.0195 38.8878 21.7815C41.1372 19.8463 42.7189 17.1327 43.2314 14.0493C43.7688 14.5799 44.5018 14.9066 45.3097 14.9066C46.958 14.9066 48.2942 13.5471 48.2942 11.8701C48.2942 10.193 46.958 8.83354 45.3097 8.83354C43.6558 8.83354 42.3162 10.2067 42.3253 11.8873C42.3201 15.613 40.6278 18.9363 37.9842 21.1089C37.8075 21.2541 37.6266 21.3942 37.4416 21.5289C35.5302 22.9209 33.1885 23.7401 30.6587 23.7401C24.2154 23.7401 18.9921 18.4257 18.9921 11.8701Z'
        fill='url(#paint3_linear_360_10352)'
      />
      <path
        d='M18.9921 11.8701C18.9921 5.31441 24.2154 0 30.6587 0C33.0314 0 35.2387 0.720672 37.0806 1.95861C34.8313 3.89384 33.2496 6.6074 32.737 9.69082C32.1996 9.16021 31.4667 8.83354 30.6587 8.83354C29.0104 8.83354 27.6742 10.193 27.6742 11.8701C27.6742 13.5471 29.0104 14.9066 30.6587 14.9066C32.3041 14.9066 33.6386 13.5518 33.6432 11.8787C33.6418 8.06985 35.4829 4.42795 38.5268 2.2112C40.4382 0.819244 42.7799 0 45.3097 0C51.753 0 56.9763 5.31441 56.9763 11.8701C56.9763 18.4257 51.753 23.7401 45.3097 23.7401C42.937 23.7401 40.7297 23.0195 38.8878 21.7815C41.1372 19.8463 42.7189 17.1327 43.2314 14.0493C43.7688 14.5799 44.5018 14.9066 45.3097 14.9066C46.958 14.9066 48.2942 13.5471 48.2942 11.8701C48.2942 10.193 46.958 8.83354 45.3097 8.83354C43.6558 8.83354 42.3162 10.2067 42.3253 11.8873C42.3201 15.613 40.6278 18.9363 37.9842 21.1089C37.8075 21.2541 37.6266 21.3942 37.4416 21.5289C35.5302 22.9209 33.1885 23.7401 30.6587 23.7401C24.2154 23.7401 18.9921 18.4257 18.9921 11.8701Z'
        fill='white'
      />
      <path
        d='M18.9921 11.8701C18.9921 5.31441 24.2154 0 30.6587 0C33.0314 0 35.2387 0.720672 37.0806 1.95861C34.8313 3.89384 33.2496 6.6074 32.737 9.69082C32.1996 9.16021 31.4667 8.83354 30.6587 8.83354C29.0104 8.83354 27.6742 10.193 27.6742 11.8701C27.6742 13.5471 29.0104 14.9066 30.6587 14.9066C32.3041 14.9066 33.6386 13.5518 33.6432 11.8787C33.6418 8.06985 35.4829 4.42795 38.5268 2.2112C40.4382 0.819244 42.7799 0 45.3097 0C51.753 0 56.9763 5.31441 56.9763 11.8701C56.9763 18.4257 51.753 23.7401 45.3097 23.7401C42.937 23.7401 40.7297 23.0195 38.8878 21.7815C41.1372 19.8463 42.7189 17.1327 43.2314 14.0493C43.7688 14.5799 44.5018 14.9066 45.3097 14.9066C46.958 14.9066 48.2942 13.5471 48.2942 11.8701C48.2942 10.193 46.958 8.83354 45.3097 8.83354C43.6558 8.83354 42.3162 10.2067 42.3253 11.8873C42.3201 15.613 40.6278 18.9363 37.9842 21.1089C37.8075 21.2541 37.6266 21.3942 37.4416 21.5289C35.5302 22.9209 33.1885 23.7401 30.6587 23.7401C24.2154 23.7401 18.9921 18.4257 18.9921 11.8701Z'
        fill='url(#paint4_linear_360_10352)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.7765 11.0788C28.1187 9.78546 29.2794 8.83354 30.6586 8.83354C31.4666 8.83354 32.1996 9.16021 32.737 9.69082C33.2495 6.6074 34.8312 3.89384 37.0806 1.95861C35.2387 0.720671 33.0314 0 30.6586 0C24.4767 0 19.4178 4.89206 19.0176 11.0788H27.7765ZM41.1479 17.0726C40.9912 17.399 40.8203 17.717 40.6359 18.0257L41.1479 17.0726ZM48.1918 12.6615C47.8496 13.9547 46.6889 14.9066 45.3097 14.9066C44.5017 14.9066 43.7687 14.5799 43.2314 14.0493C42.7188 17.1327 41.1371 19.8463 38.8878 21.7815C40.7297 23.0195 42.937 23.7401 45.3097 23.7401C51.4916 23.7401 56.5505 18.8481 56.9507 12.6615H48.1918Z'
        fill='url(#paint5_linear_360_10352)'
        fillOpacity='0.8'
      />
      <defs>
        <linearGradient
          id='paint0_linear_360_10352'
          x1='107.74'
          y1='5.00639'
          x2='93.6448'
          y2='46.7096'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_360_10352'
          x1='107.74'
          y1='5.00639'
          x2='93.6448'
          y2='46.7096'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_360_10352'
          x1='107.74'
          y1='5.00639'
          x2='93.6448'
          y2='46.7096'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_360_10352'
          x1='107.74'
          y1='5.00639'
          x2='93.6448'
          y2='46.7096'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_360_10352'
          x1='107.74'
          y1='5.00639'
          x2='93.6448'
          y2='46.7096'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFA621' />
          <stop offset='0.526042' stopColor='#FE823C' />
          <stop offset='1' stopColor='#FE5F1B' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_360_10352'
          x1='30.5984'
          y1='6.06692'
          x2='47.4057'
          y2='14.3894'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopOpacity='0' />
          <stop offset='0.288818' />
          <stop offset='0.635344' />
          <stop offset='0.997212' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  )
}
